import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Radio,
  Typography,
} from '@material-ui/core'
import {
  getApprovalStep,
  getApprovalValues,
  getEstimate,
  getUser,
  getUserPrimaryEmail,
  getapprovalValue,
} from 'ducks/selectors'
import {
  APPROVAL_TYPE,
  RESOURCE_TYPE,
  history,
  isEmpty,
  round,
} from 'helpers/index'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './../../styles'
import PaymentMethods, { RenderCardLabel } from 'components/pages/User/Settings/PaymentMethods'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { PaymentMethod } from 'ducks/user/types'
import ErrorMessage from 'components/UI/CustomUI/molecules/ErrorMessage'
import NewCardForm from 'components/UI/CustomUI/molecules/NewCardForm'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { StripeError, loadStripe } from '@stripe/stripe-js'
import env from '@beam-australia/react-env'
import { bank, icons } from 'assets'
import {
  approvalActions,
  configActions,
  estimateActions,
  paymentsActions,
  userActions,
} from 'ducks/actions'
import { toast } from 'react-toastify'
import { EstimateType } from 'ducks/estimate/types'
import { EstimateContact } from 'ducks/types'
import UsBankDisclaimerModal from './UsBankDisclaimerModal'

const stripeKey = env('STRIPE_PUBLIC_KEY') ?? ''
const stripePromise = loadStripe(stripeKey)

const PaymentContent = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const stripe = useStripe()

  const push = history.usePushForward()
  const estimate = useSelector(getEstimate())
  const user = useSelector(getUser)
  const userEmail = useSelector(getUserPrimaryEmail)
  const approvalStep = useSelector(getApprovalStep)
  // const { promoCode } = useSelector(getApprovalValues())
  const [paymentInfo, setPaymentInfo] = useState<PaymentMethod | null>(null)
  const [auxiliarPaymentInfo, setAuxiliarPaymentInfo] = useState<PaymentMethod | null>(null)
  const [newCard, setNewCard] = useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>()
  const [openUsBankModal, setOpenUsBankModal] = useState<boolean>(false)
  const { paymentMethod } = useSelector(getApprovalValues())
  const [errorMessage, setErrorMessage] = useState<
    { title: string; subtitle: string } | undefined
  >(undefined)
  const [cardIsCompleted, setCardIsCompleted] = useState(false)
  const [loading, setLoading] = useState(true)
  // const [loadingPromoCode, setLoadingPromoCode] = useState(false)
  // const [errorPromoCodeMessage, setErrorPromoCode] = useState('')
  const { paymentMethods } = useSelector(getUser)
  const newCreditCardButton = useRef<HTMLInputElement>(null)

  const depositPercentage = estimate?.properties?.depositPercentage
  const totalPrice = estimate?.properties.totalValue || 0 //totalValue
  const totalDeposit = totalPrice * ((depositPercentage || 20) / 100)

  const isDisabled =
    loading ||
    (isEmpty(paymentInfo) && !selectedPaymentMethod && !newCard) ||
    (newCard && !cardIsCompleted) ||
    !selectedPaymentMethod ||
    (selectedPaymentMethod === 'usBankAccount' && !clientSecret)

  const onChangePayment = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethod(ev.target.value)
    setNewCard(false)
    const newSelected = paymentMethods[Number(ev.target.value)]
    setPaymentInfo(newSelected)
  }
  const handleCreateCardToUser = (paymentMethod: any, type: "ach" | "credit_card") => {
    setPaymentInfo(paymentMethod)
    setSelectedPaymentMethod('0')

    const paymentMethodAlreadyExists = paymentMethods.find(
      (pm) => pm.stripeId === paymentMethod?.stripeId
    )

    if (paymentMethodAlreadyExists) {
      setLoading(false)
      return handleSuccess(type)
    }

    if (type === "ach") {
      handleSuccess(type)
    }

    if (paymentMethod?.stripeId) {
      dispatch(
        userActions.updateUserList(
          {
            attr: 'paymentMethods',
            opp: 'add',
            list: [
              {
                isDefault: true,
                stripeId: paymentMethod?.stripeId,
              },
            ],
            showError: false,
          },
          (updateSucc) => {
            if (updateSucc) {
              dispatch(
                userActions.fetchCurrentUser(() => {
                  setNewCard(false)
                })
              )
            } else {
              setLoading(false)
            }
            handleSuccess(type)
          }
        )
      )
    }

  }

  const handleCreatedPaymentMethodSuccess = (paymentMethod: any) => {
    setPaymentInfo({ ...paymentMethod.card, stripeId: paymentMethod.id, expirationMonth: paymentMethod.card.exp_month, expirationYear: paymentMethod.card.exp_year })
    setAuxiliarPaymentInfo({ ...paymentMethod.card, stripeId: paymentMethod.id, expirationMonth: paymentMethod.card.exp_month, expirationYear: paymentMethod.card.exp_year })
    setSelectedPaymentMethod('0')
    setCardIsCompleted(true)
  }

  const handlePayWithBankAccount = async () => {
    setLoading(true)
    setSelectedPaymentMethod('usBankAccount')

    setPaymentInfo(null)
    setNewCard(false)
    if (clientSecret) {
      const billingDetails = {
        email: userEmail,
        name: `${user?.firstName} ${user?.lastName}`,
      }
      setLoading(false)

      const { paymentIntent, error }: any =
        await stripe?.collectBankAccountForPayment({
          clientSecret: clientSecret,
          params: {
            payment_method_type: 'us_bank_account',
            payment_method_data: {
              billing_details: billingDetails as any,
            },
          },
        })

      setClientSecret(paymentIntent?.client_secret)
      if (paymentIntent?.status !== 'requires_confirmation' || error) {
        //stripe returns "requires_confirmation" when the bank account details were delivered.
        setSelectedPaymentMethod(undefined)
      } else {
        toast.success('Bank account added successfully')
      }
    }
  }

  const pay = async () => {
    if (selectedPaymentMethod === 'usBankAccount') {
      const { paymentIntent: paymentConfirm, error: errorPayment }: any =
        await stripe?.confirmUsBankAccountPayment(clientSecret, {
          save_payment_method: true,
        })

      if (paymentConfirm) {
        dispatch(
          approvalActions.setApprovalValue({
            attr: 'paymentMethod',
            value: 'ach',
          })
        )
        handleCreateCardToUser(paymentInfo, "ach")
      }
      if (errorPayment) {
      }
    } else {
      if (!stripe) return

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentInfo?.stripeId || paymentInfo?.id,
        }
      )
      if (paymentIntent?.status === 'succeeded') {
        dispatch(
          approvalActions.setApprovalValue({
            attr: 'paymentMethod',
            value: 'credit_card',
          })
        )
        handleCreateCardToUser(paymentInfo, "credit_card")
      }
      if (error) {
        setLoading(false)
        handleStripeErrorMessage(error)
      }
    }
  }

  function handleStripeErrorMessage(error: StripeError) {
    switch (error.code) {
      case 'payment_intent_incompatible_payment_method':
        setErrorMessage({
          title: 'Whoops!',
          subtitle:
            "Looks like you haven't attached a payment method. Please attach a card and then complete your purchase.",
        })
        return
      case 'card_declined':
        if (error.decline_code === 'insufficient_funds') {
          setErrorMessage({
            title: 'Whoops!',
            subtitle:
              "Looks like you haven't attached a payment method. Please attach a card and then complete your purchase.",
          })
        } else {
          setErrorMessage({
            title: 'Whoops!',
            subtitle:
              error.message ||
              'Looks like you we have an error. Please try again',
          })
        }
        return
      default:
        setErrorMessage({
          title: 'Whoops!',
          subtitle:
            error.message ||
            'Looks like you we have an error. Please try again',
        })
        return
    }
  }

  const handleSuccess = (paymentSelected: 'ach' | 'credit_card') => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )
    const contacts: EstimateContact[] = estimate?.properties?.contacts?.reduce(
      (acc: any, curr: Partial<EstimateContact>) => {
        if (curr.email === userEmail) {
          acc = [
            ...acc,
            {
              ...curr,
              approvalType: APPROVAL_TYPE.APPROVED,
              isPayer: true,
            },
          ]
        } else {
          acc = [...acc, curr]
        }
        return acc
      },
      []
    )
    const payload: Partial<EstimateType> = {
      id: estimate?.id,
      properties: {
        address: estimate?.properties?.address,
        payAtClose: false,
        accessDetails: estimate?.properties?.accessDetails,
        preferredCompletedOn: estimate?.properties?.preferredCompletedOn,
        closingDate: estimate?.properties?.closingDate,
        contacts: contacts,
        userId: user?.id,
        clientNotes: estimate?.properties?.clientNotes,
        totalValue: estimate?.properties?.totalValue ?? 0,
        approvalStep: 0,
      },
    }
    if (paymentSelected === 'ach')
      payload.approvalStatus = 'PROCESSING_ACH_PAYMENT'
    dispatch(
      estimateActions.approval(
        payload,
        (success: boolean, status: string, payAtClose) => {
          dispatch(
            configActions.setConfigValue({
              type: 'overlayLoading',
              value: false,
            })
          )
          if (success) {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )
            push(`success`)
          }
        }
      )
    )
  }

  useEffect(() => {
    dispatch(
      userActions.fetchCurrentUser((_succ) => {
        dispatch(
          paymentsActions.fetchStripeSecret(
            {
              amount: totalDeposit,
              resourceId: estimate?.id || '',
              resourceType: RESOURCE_TYPE.JOB,
              savePaymentMethod: true,
              customer: user?.customerId || '',
              createInvoice: true,
            },
            async (clientSecret?: string) => {
              if (clientSecret) {
                setLoading(false)
                setClientSecret(clientSecret)
              }
            }
          )
        )
      })
    )
  }, [])

  const handleCloseBankModal = (accepted?: boolean) => {
    setOpenUsBankModal(false)
    accepted && handlePayWithBankAccount()
  }

  const handleSelectAuxiliarCard = () => {
    setPaymentInfo(auxiliarPaymentInfo);
    setSelectedPaymentMethod('0');
    setNewCard(true);
    setCardIsCompleted(true)
  }

  const auxiliarPaymentSelected = JSON.stringify(paymentInfo) === JSON.stringify(auxiliarPaymentInfo)

  return (
    <Grid container className={classes.container}>
      <>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <UsBankDisclaimerModal
              onSubmit={handleCloseBankModal}
              open={openUsBankModal}
              setOpen={setOpenUsBankModal}
            />
            <Grid item>
              <Typography className={classes.title}>
                💳 deposit payment
              </Typography>
              <Typography style={{ color: 'var(--bosscat-black-400)' }}>
                A 20% deposit is required for all projects.
              </Typography>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              style={{ marginBottom: '7rem' }}
              className={classes.rootContainer}
            >
              <Grid item xs={12} lg={6}>
                <Box
                  className={`${classes.spacedItems} ${classes.paymentMethod} ${classes.addNewCard}`}
                  // onClick={handlePayWithBankAccount}
                  onClick={() => setOpenUsBankModal(true)}
                //style={errorMessage ? { borderColor: '#E01F1F' } : {}}
                >
                  <Grid item className={classes.newCardFormContainer}>
                    <img
                      src={bank}
                      alt="Credit Card"
                      className={classes.cardLogo}
                    />
                    <Box style={{ paddingLeft: '12px', width: '100%' }}>
                      <Typography className={`${classes.addNew}`}>
                        US Bank Account
                      </Typography>
                      <Typography className={classes.disclaimer}>
                        Takes between 24 to 48 hours to receive the payment.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Radio
                      checked={selectedPaymentMethod === 'usBankAccount'}
                      classes={{ checked: classes['Mui-checked'] }}
                    // onChange={handlePayWithBankAccount}
                    />
                  </Grid>
                </Box>
                <PaymentMethods
                  onChange={onChangePayment}
                  selected={!newCard && paymentInfo}
                />
                {auxiliarPaymentInfo && <Box onClick={handleSelectAuxiliarCard} className={`${classes.spacedItems} ${classes.paymentMethod} ${classes.card} ${auxiliarPaymentSelected && classes.selectedCard}`}><RenderCardLabel card={auxiliarPaymentInfo} index={99} /> <Radio classes={{ checked: classes['Mui-checked'], root: classes.customHeight }} checked={auxiliarPaymentSelected} /></Box>}
                <Box
                  className={`${classes.spacedItems} ${classes.paymentMethod} ${classes.addNewCard}`}
                >
                  <Grid item className={classes.newCardFormContainer}>
                    <NewCardForm
                      submitButtonRef={newCreditCardButton}
                      onSuccess={handleCreatedPaymentMethodSuccess}
                      onFocus={() => {
                        setNewCard(true)
                      }}
                      customErrorMessage={(error) => (
                        <ErrorMessage
                          error={{ title: 'Whoops!', subtitle: error }}
                        />
                      )}
                      finishLoading={() => {
                        // setLoading(false)
                      }}
                      // type={isMobile ? 'modal' : 'inline'}
                      showButton={true}
                      onChange={(isCompleted) => {
                        setCardIsCompleted(isCompleted)
                      }}
                      type="modal"
                    />
                  </Grid>
                </Box>
                <ErrorMessage error={errorMessage} />
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  item
                  className={classes.orderSummaryContainer}
                  xs={12}
                  direction="column"
                >
                  <Grid item>
                    <Typography className={classes.orderSummaryTitle}>
                      Order Summary
                    </Typography>
                    <Typography className={`${classes.bold} ${classes.plan}`}>
                      Project Deposit ({depositPercentage}%)
                    </Typography>
                  </Grid>
                  {/*  <Grid
                    item
                    style={{ margin: '0.1rem 0' }}
                  >
                    <PromotionalCode
                      onSubmit={(value) => {
                        setErrorPromoCode('')
                        value &&
                          dispatch(
                            estimateActions.applyPromoCode(value, (succ) => {
                              setLoadingPromoCode(false)
                              if (succ) {
                                dispatch(
                                  estimateActions.fetchEstimate(
                                    estimate?.id || ''
                                  )
                                )
                                // setPromoSuccess(true)
                              } else {
                                setErrorPromoCode(
                                  'Wrong promo code. Check your code to apply it.'
                                )
                              }
                            })
                          )
                        // submit promo code
                      }}
                      errorMessage={errorPromoCodeMessage}
                      loading={loadingPromoCode}
                      promoCodeAlreadyApplied={!isEmpty(estimate?.promotionId)}
                      promoCode={promoCode || ''}
                      onChange={(value) => saveNewValue('promoCode', value)}
                    />
                  </Grid> */}
                  <Divider variant="fullWidth" className={classes.divider} />
                  <Grid
                    item
                    container
                    direction="column"
                    // className={`${classes.padding}`}
                    spacing={1}
                  >
                    <Grid item>
                      <Typography className={`${classes.bold} ${classes.plan}`}>
                        Deposit Total: ${round(totalDeposit, 2)}
                      </Typography>
                      <Typography className={classes.orderSummaryTitle}>
                        Project Total: ${round(totalPrice, 2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
      <Grid
        item
        container
        justifyContent="space-between"
        className={`${classes.buttonsContainer}`}
      >
        <Button
          // variant="outlined"
          size="small"
          onClick={() => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: true,
              })
            )

            dispatch(
              estimateActions.updateEstimateProperties(
                {
                  approvalStep: approvalStep - 1,
                },
                (succ) => {
                  dispatch(
                    configActions.setConfigValue({
                      type: 'overlayLoading',
                      value: false,
                    })
                  )
                }
              )
            )
            // dispatch(approvalActions.setApprovalStep(step - 1))
          }}
          className={classes.buttonBack}
          disabled={loading}
          startIcon={<icons.ArrowBack className={classes.buttonSubmitIcon} />}
        >
          Back
        </Button>
        <Button
          onClick={pay}
          className={classes.buttonSubmit}
          disabled={isDisabled}
          style={
            isDisabled
              ? {
                color: 'rgba(0, 0, 0, 0.26)',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
              }
              : {}
          }
          endIcon={
            !loading && (
              <icons.ArrowForward className={classes.buttonSubmitIcon} />
            )
          }
        >
          {selectedPaymentMethod === 'usBankAccount'
            ? 'Submit Payment'
            : 'Schedule My Project!'}
        </Button>
      </Grid>
    </Grid>
  )
}

const PaymentScreen = () => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            src: 'url(/assets/fonts/Lato-Regular.ttf) format(ttf)',
            family: 'Lato-Normal',
          },
        ],
        locale: 'auto',
      }}
    >
      <PaymentContent />
    </Elements>
  )
}

export default PaymentScreen
